import { FC } from 'react';
import tw from 'twin.macro';
import {
  ContentSection,
  Footnotes,
  H2,
  Layout,
  LottieTwoUp,
  LottieWrapper,
  SEO,
  Space,
} from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Lottie Component and Two-Up',
  orderInLib: 10,
  screenShoot: false,
};

const LibraryPage: FC = () => {
  return (
    <Layout>
      <SEO />
      <ContentSection>
        <H2>Lottie with fallback image</H2>
        <LottieWrapper
          desktopLottiePath={'/lotties/Desktop-MBC-24.json'}
          mobileLottiePath={'/lotties/MBC-24.json'}
          alt="invasive disease-free survival in the HER2+ HR+ subgroup that completed therapy shows 56% reduction in risk of recurrence at 5 years"
          customCss={tw`max-width[800px] margin[0 auto]`}
        />

        <Space xxl />
        <H2>Two-Up with Lottie and Text</H2>
        <LottieTwoUp
          columnOne={
            <LottieWrapper
              desktopLottiePath={'/lotties/Desktop-MBC-24.json'}
              mobileLottiePath={'/lotties/MBC-24.json'}
              alt="invasive disease-free survival in the HER2+ HR+ subgroup that completed therapy shows 56% reduction in risk of recurrence at 5 years"
            />
          }
          columnTwo={
            <div>
              <h3>Heading</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Molestias, voluptates.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Eveniet maiores provident voluptatem veniam nesciunt quis error
                dolorum qui voluptate voluptatibus.
              </p>
              <Space />
              <Footnotes
                footnotes={['recurrence', 'results']}
                references={['1', '1,2']}
                abreviations={['CI', 'HR--hazard', 'HR+', 'iDFS', 'ITT', 'pCR']}
              />
            </div>
          }
        />
        <Space xxl />
      </ContentSection>
    </Layout>
  );
};

export default LibraryPage;
